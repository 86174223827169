<template>
  <div>
    <page-header has-back>
    </page-header>
    <div class="layout-main-body">
      <el-card shadow="never" v-loading="loading">
        <el-row>
          <el-col>
            <el-form :model="editForm" :rules="rules" ref="editForm" label-width="200px">
              <el-form-item label="模板类型" prop="type">
                <el-select v-model="editForm.type" placeholder="类型" :disabled="!!editForm.id">
                  <el-option :value="1" label="水印图片"></el-option>
                  <el-option :value="2" label="视频片头"></el-option>
                  <el-option :value="3" label="视频片尾"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="模板名称" prop="name">
                <el-input v-model="editForm.name" style="width: 400px"></el-input>
              </el-form-item>
              <!--          <el-form-item label="排序">-->
              <!--            <el-input-number v-model="editForm.position"></el-input-number>-->
              <!--          </el-form-item>-->
              <!--          <el-form-item label="是否平台默认">-->
              <!--            <el-checkbox v-model="editForm.isPlatform" @change="handlePlatformChange"></el-checkbox>-->
              <!--          </el-form-item>-->
              <el-form-item label="选择用户" v-if="!editForm.isPlatform">
                <remote-select v-model="editForm.userId"
                               width="400"
                               width-input="400px"
                               placement="bottom"
                               url="/user/list"
                               :columns="[{field:'nickName',name:'昵称'},{field:'mobile',name:'手机号'}]"
                               label="nickName"
                               value-key="id"
                               :size="5"
                               clearable></remote-select>
              </el-form-item>
              <el-form-item label="水印图片" v-if="editForm.type === 1">
                <el-upload
                    class="avatar-uploader"
                    action="#"
                    :show-file-list="false"
                    :limit="1"
                    :before-upload="handleWatermarkBeforeUpload">
                  <img v-if="editForm.imageUrl" :src="editForm.imageUrl" class="avatar"/>
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
              <el-form-item label="上传视频" v-if="editForm.type === 2 || editForm.type === 3">
                <video-upload v-model="video" width="260px"></video-upload>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" :loading="loadingSave" @click="handleSave">保存</el-button>
                <el-button plain @click="handleGotoEditPage">继续新增</el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </el-card>
    </div>
  </div>
</template>

<script>
import { CommonQiniuApi, UserVideoTplApi } from '@/api'
import { UserVideoTplType } from '@/utils/contants'

const defaultForm = () => {
  return {
    id: null,
    type: UserVideoTplType.WATERMARK,
    name: '',
    userId: '',
    imageUrl: '',
    videoUrl: '',
    videoCoverUrl: '',
    videoTime: 0,
    isPlatform: false
  }
}

export default {
  name: 'VideoTplEdit',
  data () {
    return {
      editForm: defaultForm(),
      rules: {
        type: [
          {
            required: true,
            message: '请选择模版类型',
            trigger: 'change'
          }
        ]
      },
      loadingSave: false,
      loading: false,
      video: null
    }
  },
  methods: {
    initData () {
      const id = this.$route.query.id
      if (id) {
        this.loading = true
        UserVideoTplApi.get(id).then((data) => {
          this.loading = false
          Object.assign(this.editForm, data)
          this.video = {
            video: { fileUrl: this.editForm.videoUrl },
            cover: { fileUrl: this.editForm.videoCoverUrl }
          }
        }).catch(() => {
          this.loading = false
        })
      } else {
        this.editForm = defaultForm()
        this.video = null
      }
    },
    handleWatermarkBeforeUpload (file) {
      const data = new FormData()
      data.append('file', file)
      CommonQiniuApi.upload(data).then((result) => {
        this.editForm.imageUrl = result.fileUrl
      })
      return false
    },
    handleSave () {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          this.loadingSave = true
          if (this.video) {
            this.editForm.videoUrl = this.video.video.fileUrl
            this.editForm.videoCoverUrl = this.video.cover.fileUrl
            this.editForm.videoTime = this.video.video.fileTime
          }
          UserVideoTplApi.save(this.editForm).then((data) => {
            this.loadingSave = false
            Object.assign(this.editForm, data)
            this.$message.success('保存成功')
          }).catch(() => {
            this.loadingSave = false
          })
        } else {
          return false
        }
      })
    },
    handleGotoEditPage () {
      this.$router.replace({
        path: '/refresh',
        query: {
          id: '',
          t: Date.now()
        }
      })
    }
  },
  created: function () {
    this.initData()
  }
}
</script>

<style lang="less">

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
